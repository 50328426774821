import { AuthService } from '@/api'
import { balanceService } from '@/api/balance'
import { Switch } from '@/cn/ui/switch'
import { useToast } from '@/cn/ui/use-toast'
import { NETWORKS, NETWORKS_BY_CHAINID, REFETCH_INTERVAL } from '@/constants'
import { IChain, INetworks, IUser } from '@/interface'
import { popup } from '@/popup_handler'
import { useAppStore } from '@/store'
import { CopyButton, Loading } from '@/ui'
import { TitleBar } from '@/ui/titlebar'
import { CURRENCY, HIDE, SHOW, localizeFiat, prettifyAddress, queryClient } from '@/utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ChevronDown, DollarSign, Key, Link, LogOut, Pencil, Wand } from 'lucide-react'
import { ReactNode, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
const TESTNET_NETWORKS = Object.values(NETWORKS).filter((_) => _.isTestnet)
const MAINNET_NETWORKS = Object.values(NETWORKS).filter((_) => !_.isTestnet)

const Setting = () => {
  const { toast } = useToast()
  const { wallet } = useAppStore((state) => state) as any
  const { network, changeNetwork } = useAppStore((state) => state) as any
  const user = (queryClient.getQueryData(['user']) || {}) as IUser
  const currentNetwork = NETWORKS_BY_CHAINID?.[user?.default_chain]
  const currentCurrency = useMemo(() => {
    return CURRENCY.find((_) => _.name === user?.default_currency?.name)
  }, [user.default_currency])

  const balance = useQuery(['balance', wallet?._id, network], () => balanceService.Get({ address: wallet?.address }), {
    refetchInterval: REFETCH_INTERVAL,
  })
  const usdAmount = balance.data?.result?.reduce((a: any, b: any) => a + parseFloat(b.usdBalance), 0)
  const mutation = useMutation(() => AuthService.Logout(), {
    onSuccess: () => {
      ;(window as any).abortRequest()
      // ;(window as any).controller.abort()
      queryClient.setQueryData(['user'], () => {
        close()
        return null
      })
    },
  })
  const navigate = useNavigate()
  const handleNavigate = (to: string) => {
    close()
    navigate(to)
  }
  const ITEMS: { title: string; description: string; icon: ReactNode; to?: string; cb?: Function }[] = [
    // {
    //     title: "Manage accounts",
    //     description: "View a list of all the accounts you've imported in-app, rename them and change settings.",
    //     icon: <List className="text-foreground w-4" />,
    //     to: "/dashboard/manage-accounts",
    // },
    {
      title: 'Backup and recovery',
      description: 'Set up account recovery or recover your account from another device.',
      icon: <Key className="text-foreground w-4" />,
      // to: "/dashboard/backup-and-recovery",
      cb: () => popup.open('backup_and_recovery'),
    },
    {
      title: 'Connected DApps',
      description: 'Set up account recovery or recover your account from another device.',
      icon: <Key className="text-foreground w-4" />,
      // to: "/dashboard/backup-and-recovery",
      cb: () => popup.open('connected_dapps'),
    },

    // {
    //     title: "Change your currency",
    //     description: "You will primarily send or receive money in the currency of your choice.",
    //     icon: <DollarSign className="text-foreground w-4" />,
    //     to: "",
    // },
  ]
  const handleSwitchNetwork = async (mainnet: boolean) => {
    changeNetwork(mainnet ? INetworks.MAINNET : INetworks.TESTNET)

    await AuthService.UpdateProfile({ default_chain: mainnet ? MAINNET_NETWORKS[0].chainId : TESTNET_NETWORKS[0].chainId })
    await queryClient.setQueryData(['user'], (data: any) => {
      data.default_chain = mainnet ? MAINNET_NETWORKS[0].chainId : TESTNET_NETWORKS[0].chainId
      return data
    })

    toast({
      title: `Network switched to ${mainnet ? 'Mainnet' : 'Testnet'}`,
    })
  }
  const profileMutation = useMutation((payload: any) => AuthService.UpdateProfile(payload), {
    onSuccess: async (_, payload) => {
      await queryClient.invalidateQueries(['user'])
      toast({
        title: payload.default_currency
          ? `Switched to ${payload.default_currency} successfully!`
          : payload.default_chain
          ? `Switched to  ${(NETWORKS_BY_CHAINID as any)[payload.default_chain].name} successfully!`
          : 'ProfileUpdated',
      })
    },
  })
  const handleChainSelect = () => {
    popup.open('select_chain', {
      currentChain: currentNetwork,
      setCurrentChain: (chain: IChain) => {
        profileMutation.mutate({ default_chain: chain.chainId })
      },
    })
  }
  const handleCurrencySelect = () => {
    popup.open('select_currency', {
      currentCurrency,
      setCurrentCurrency: (currency: any) => {
        profileMutation.mutate({ default_currency: currency.name })
      },
    })
  }
  return (
    <div className="flex flex-col gap-4 p-4 h-full">
      <TitleBar title="Settings" navClassName="sticky top-0" />
      <div className="flex flex-col gap-2 flex-grow overflow-scroll">
        <h3 className="text-foreground capitalize">{user.name}</h3>
        <div className="flex items-center justify-between">
          <div>
            <p className="text-foreground flex items-center gap-4">
              <div>
                <span>CPI ID:</span> {user.username}@cray
              </div>
              <button onClick={() => popup.open('update_cid', user.username)} className="flex items-center justify-center md:hover:bg-background active:opacity-70 rounded-full w-8 h-8">
                <Pencil className="w-4" />
              </button>
            </p>
            <p className="flex gap-2 items-center font-bold rounded-[6px] inline-code">
              {prettifyAddress(wallet?.address)} <CopyButton value={wallet?.address || ''} />
            </p>
            <p className="flex gap-2 mt-1 items-center rounded-[6px]">{user.email}</p>
          </div>
          <div {...SHOW(balance.isLoading)} className="h-9 w-16 rounded-sm bg-background/50 animate-pulse"></div>
          <h2 className="text-foreground" {...HIDE(balance.isLoading)}>
            {localizeFiat(usdAmount || 0)}
          </h2>
        </div>
        <hr className=" text-gray-300 h-1" />
        <div className="flex flex-col gap-2 py-2 px-4">
          <div className="flex items-center gap-2">
            <Wand className="text-foreground w-4" />
            <p className=" text-foreground">Testnet</p>
            <div className=" flex-grow flex justify-end">
              <Switch checked={network === INetworks.MAINNET} onCheckedChange={handleSwitchNetwork} disabled />
            </div>
          </div>
          <span className=" w-full text-left">
            You're currently in TESTNET. Switch to the {network === INetworks.MAINNET ? 'Testnet' : 'Mainnet'} version of the app.
            {/* You're currently in {network === INetworks.MAINNET ? 'Mainnet' : 'Testnet'}. Switch to the {network === INetworks.MAINNET ? 'Testnet' : 'Mainnet'} version of the app. */}
          </span>
        </div>
        {ITEMS.map((item) => (
          <button
            onClick={() => (item.cb ? item.cb() : handleNavigate(item.to || ''))}
            key={item.title}
            className="flex flex-col gap-2 py-2 px-4 cursor-pointer md:hover:bg-foreground/10 rounded-md active:bg-foreground/5"
          >
            <div className="flex items-center gap-2">
              {item.icon}
              <p>{item.title}</p>
            </div>
            <span className="  w-full text-left">{item.description}</span>
          </button>
        ))}
        <button className="flex flex-col gap-2 py-2 px-4 cursor-pointer rounded-md ">
          <div className="flex items-center gap-2">
            <Link className="text-foreground w-4" />
            <p>Set default Network</p>
          </div>

          <button onClick={handleChainSelect} className="flex justify-between items-center p-2 cursor-pointer md:hover:bg-gray-100 active:bg-gray-100/80 border rounded-[16px] w-full">
            <div className="flex items-center gap-2">
              <img className="h-4 w-4" src={currentNetwork?.logo} />
              <div className="flex flex-col gap-2">
                <p>{currentNetwork?.name}</p>
              </div>
            </div>
            <ChevronDown />
          </button>
        </button>

        <button className="flex flex-col gap-2 py-2 px-4 cursor-pointer rounded-md ">
          <div className="flex items-center gap-2">
            <DollarSign className="text-foreground w-4" />
            <p>Set default Currency</p>
          </div>

          <button onClick={handleCurrencySelect} className="flex justify-between items-center p-2 cursor-pointer md:hover:bg-gray-100 active:bg-gray-100/80 border rounded-[16px] w-full">
            <div className="flex items-center gap-2">
              <span>{currentCurrency?.symbol}</span>
              <div className="flex flex-col gap-2">
                <p>{currentCurrency?.name}</p>
              </div>
            </div>
            <ChevronDown />
          </button>
        </button>

        <p onClick={() => mutation.mutate()} className="flex items-center gap-2 px-4 py-2  cursor-pointer text-red-500">
          {mutation.isLoading ? <Loading /> : <LogOut size={16} />}
          Logout
        </p>
      </div>
    </div>
  )
}
export default Setting
