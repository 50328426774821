export * from './contacts_api'
export * from './crypto'
export * from './file'
export * from './localize_fiat'
export * from './search_in_json'
export * from './wallet'
export * from './xhr'
import { PaymentService } from '@/api'
import { popup } from '@/popup_handler'
import { QueryClient } from '@tanstack/react-query'
import { Core } from '@walletconnect/core'
import { IWeb3Wallet, Web3Wallet } from '@walletconnect/web3wallet'
import { clsx, type ClassValue } from 'clsx'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import QrScanner from 'qr-scanner'
import { twMerge } from 'tailwind-merge'
import { NativeBridge } from './native_bridge'

export const sortTokens = (a: any, b: any) => parseFloat(b.usdBalance) - parseFloat(a.usdBalance)
export const sortByTime = (a: any, b: any) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
export const PreventDefault = (e: any) => e.preventDefault()
export const toUint8Array = (data: string) => new TextEncoder().encode(data)
export const arrayBuffertoString = (data: ArrayBuffer) => new TextDecoder().decode(data)
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const HIDE = (condition: boolean) => ({
  ...(condition && { hidden: true }),
})
export const SHOW = (condition?: boolean) => ({
  ...(!condition && { hidden: true }),
})

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always',
      cacheTime: Infinity,
    },
    mutations: {
      networkMode: 'always',
    },
  },
})

export const _sleep = (ms: number) => new Promise((resolve) => setTimeout(() => resolve(true), ms))

export const objectToQueryParams = (obj: any) =>
  Object.keys(obj)
    .map((key) => key + '=' + (obj[key] ?? ''))
    .join('&')

export const stopPropogation = (e: React.MouseEvent) => e.stopPropagation()
export function prettifyAddress(hash: string = '') {
  return hash.substr(0, 6) + '...' + hash.substr(-4)
}
export async function sha256(message: string | ArrayBuffer) {
  // encode as UTF-8
  if (typeof message === 'string') {
    message = new TextEncoder().encode(message)
  }
  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', message)

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}
export function hexStringToArrayBuffer(hexString: string) {
  // remove the leading 0x
  hexString = hexString.replace(/^0x/, '')

  // ensure even number of characters
  if (hexString.length % 2 != 0) {
    console.log('WARNING: expecting an even number of characters in the hexString')
  }

  // check for some non-hex characters
  var bad = hexString.match(/[G-Z\s]/i)
  if (bad) {
    console.log('WARNING: found non-hex characters', bad)
  }

  // split the string into pairs of octets
  var pairs = hexString.match(/[\dA-F]{2}/gi) || []

  // convert the octets to integers
  var integers = pairs.map(function (s) {
    return parseInt(s, 16)
  })

  var array = new Uint8Array(integers)
  // console.log(array)

  return array //.buffer
}

export const downloadFile = (data: string, fileName = 'file') => {
  var encodedUri = encodeURI('data:text/plain;charset=utf-8,' + data)
  var link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', fileName + '.txt')
  document.body.appendChild(link) // Required for FF
  link.click()
}
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBr4E1C0AGjzvGRQKTcEzVcQVnJRyHa6v4',
  authDomain: 'cray-5b450.firebaseapp.com',
  projectId: 'cray-5b450',
  storageBucket: 'cray-5b450.appspot.com',
  messagingSenderId: '569436314179',
  appId: '1:569436314179:web:a58c483f31c46344ad52f6',
  measurementId: 'G-58H693VH0B',
}
// // Initialize Firebase
let app: any
let messaging: any
if (!NativeBridge.isBridge()) {
  app = initializeApp(firebaseConfig)
  messaging = getMessaging(app)
  self.addEventListener('notificationclick', function (event: any) {
    event.notification.close()
  })
  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload)
  })
}
export const getFCMToken = async () => {
  return new Promise((resolve, reject) => {
    if (NativeBridge.isBridge()) return resolve(true)
    getToken(messaging, {
      vapidKey: 'BGwWpoEcaox_WjMLN9J6tO2X8E1O73iNcpdPsRdS56AZMbhSD5-mH-zBPZDWG0esSClBW0PZeOpFzgQXAJ7ja5U',
    })
      .then((currentToken) => {
        if (currentToken) {
          resolve(currentToken)
        } else {
          console.log('No registration token available. Request permission to generate one.')
        }
      })
      .catch((err) => {
        reject(err)
        // console.log("An error occurred while retrieving token. ", err);
      })
  })
}
export const parseErrorMessage = (error: any, error_map: { [key: string]: string }) => {
  let errorKey = Object.keys(error_map).find((key) => String(error).includes(key))
  if (errorKey) {
    return error_map[errorKey]
  } else {
    return error?.message || 'Something went wrong, please try again'
  }
}

export const handleInvite = async () => {
  const message = `Hey there! 👋🏻
I've been using Cray for everyday stablecoin transactions, and it's smooth as a breeze!

They're in beta, so join the waitlist now because seamless crypto payments are definitely worth it! -> 
https://testflight.apple.com/join/j4EcVZBT

Catch you soon! ✌🏻`
  if (NativeBridge.isBridge()) {
    await NativeBridge.Send('SHARE', { message })
  } else {
    ;(window as any).popup.open('invite', message)
  }
}

export function generateColour(str: string) {
  const COLORS = ['#5917C1', '#55a2ff', '#65def1', '#c3df86', '#ef516c', '#e9ebf6', '#233d4d', '#f75590']

  let hash = 0
  str.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
  })
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += value.toString(16).padStart(2, '0')
  }
  return COLORS[Math.abs(hash) % COLORS.length]
}

export const wcCore = new Core({
  projectId: import.meta.env.VITE_WALLET_KIT_PROJECT_ID,
})

const metadata = {
  name: 'cray',
  description: 'AppKit Example',
  url: 'http://localhost:5173', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
}
export let web3wallet: IWeb3Wallet
;(async () => {
  web3wallet = await Web3Wallet.init({
    core: wcCore, // <- pass the shared 'core' instance
    metadata,
  })
  web3wallet.on('session_proposal', (data) => popup.open('connect_wc_wallet', data))
  web3wallet.on('session_request', (data) => popup.open('sign_request', data))
})()

export function getVersion(version: string) {
  const [major, minor, patch] = version.split('.')
  return Number(major || 0) * 100 + Number(minor || 0) * 10 + Number(patch || 0)
}
export const handleQrScan = async ({ navigation }: any) => {
  try {
    if (NativeBridge.isBridge()) {
      let data = (await NativeBridge.Send('SCAN_QR')) as any
      if (data.includes('data:image/png;base64,')) {
        /** qr uploaded by native app */
        data = await QrScanner.scanImage(data)
      }
      if (data.startsWith('wc:')) {
        /** wallet connect qr scan */
        return await wcCore.pairing.pair({
          uri: data,
        })
      } else if (data.startsWith('cqpr-')) {
        const [_, orderId] = data.split('-')
        let { result } = await PaymentService.StartQrPayment({
          orderId,
        })
        popup.open('validate_send', {
          amount: result.metaData.amount,
          order: result,
          toAddress: result.metaData.to,
        })
      } else {
        navigation('/dashboard/send-money', {
          state: {
            address: data,
          },
        })
      }
    } else {
      popup.open('camera', { cb: (data: any) => popup.open('send', data) })
    }
  } catch (error) {
    alert(error)
  }
}
;(BigInt.prototype as any).toJSON = function () {
  return this.toString()
} /** patch big int https://github.com/GoogleChromeLabs/jsbi/issues/30#issuecomment-953187833 */
