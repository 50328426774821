import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { queryClient } from './utils/index.ts'
import ErrorBoundaryComponent from './ui/error_boundary.tsx'
import { Toaster } from './cn/ui/toaster.tsx'
import * as Sentry from '@sentry/react'
import { NativeBridge } from './utils/native_bridge.ts'
import { IOS_TRANSITION, NETWORKS_BY_CHAINID, SIGN_TYPE, WEB_TRANSITION } from './constants/index.ts'
import erc20ABI from '@/constants/erc20.abi.json'
const IS_BRDIGE = NativeBridge.isBridge()

const transitionCss = IS_BRDIGE && (window as any).IS_IOS ? IOS_TRANSITION : WEB_TRANSITION
const persister = createSyncStoragePersister({
  storage: window.localStorage,
})
const logError = (error: any, { componentStack }: any) => {
  if (import.meta.env.MODE !== 'development') {
    Sentry.captureException(error, {
      contexts: { react: { componentStack } },
    })
  }
  console.log(error, componentStack)
  queryClient.clear() // clear cached data
}

if (import.meta.env.MODE !== 'development') {
  Sentry.init({
    dsn: 'https://22055ff2a2e06dc7ef46923a5283bacf@o4507492800266240.ingest.us.sentry.io/4507492814356480',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['https://alpha.cray.network'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
const IGNORE_CACHE_KEYS = ['user'] /**dont' cache user auth status */
const syncRpcToNativeDevice = () => {
  if (NativeBridge.isBridge()) {
    NativeBridge.Send('BOOT_UP', { networks: NETWORKS_BY_CHAINID, erc20ABI, signType: SIGN_TYPE }) /** sync networks to native device  */
  }
}
if (import.meta.env.MODE !== 'development') {
  syncRpcToNativeDevice()
} else {
  setInterval(syncRpcToNativeDevice, 1000)
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <PersistQueryClientProvider
    persistOptions={{
      persister,
      dehydrateOptions: {
        shouldDehydrateQuery: (query) => !IGNORE_CACHE_KEYS.includes(String(query.queryKey?.[0])),
      },
    }}
    client={queryClient}
  >
    <BrowserRouter basename="">
      <ErrorBoundary FallbackComponent={ErrorBoundaryComponent} onError={logError}>
        <style>{transitionCss}</style>
        <App />
        <Toaster />
      </ErrorBoundary>
    </BrowserRouter>
  </PersistQueryClientProvider>
)
