import { IPaymantPayload } from '@/interface'
import { get, post } from '@/utils'
export interface IPaymantBreakdownPayload {
  senderAddress: string
  receiverAddress: string
  amount: string
}
class Payment {
  GetDataToSign = (payload: IPaymantPayload) => post('/pay/create/', payload)
  GetUserByUserId = (userId: string) => get(`/users/userId/${userId}/account`)
  getAllowanceStatus = (payload: IPaymantBreakdownPayload) => post('/pay/validate-payment/', payload)
  GetUserAddressByCPID = (username: string) => get(`/users/cpid/${username}/account`)
  GetUserAddressByAddress = (address: string) => get(`/users/address/${address}/account`)
  BroadcastTransaction = (orderId: string, payload: any) => post(`/pay/broadcast/${orderId}`, payload)
  ProcessOrder = (orderId: string, payload: any) => post(`/pay/process-order/${orderId}`, payload)
  StartQrPayment = (payload: any) => post(`/pay/start-qr-payment`, payload)
  RejectTransaction = (orderId: string) => post(`/pay/reject/${orderId}`)
  RecentActivity = () => get(`/pay/recent-activity`)
}
export const PaymentService = new Payment()
