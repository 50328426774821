import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import SendMoney from './send_money_home'

const SendHome = () => {
  const location = useLocation()
  if (!location.state) {
    return <Navigate to="/dashboard" />
  }
  return (
    <Routes>
      <Route index element={<SendMoney />} />
    </Routes>
  )
}
export default SendHome
